import React, { useState } from "react";

import { Box, Button, TextField, Typography } from "@mui/material";

import { createSelector } from "reselect";

import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";

import { tryLogin } from "@common/actions/auth.action";
import { validationSchema } from "@auth-module/validation";

const selector = createSelector(
  (state) => state.config,
  (state) => state.auth,
  ({ loading }, { is_logged }) => ({
    loading,
    is_logged,
  })
);

const LoginPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const state = useSelector(selector);

  const _user = useSelector((state) => state.auth.user);

  const {
    formState: { errors },
    register,
    setError,
    handleSubmit,
  } = useForm({
    mode: "onChange",
    shouldFocusError: true,
    resolver: yupResolver(validationSchema),
  });

  const [values, setValues] = useState({
    username: "",
    password: "",
  });

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleLogin = async () => {
    const { password, username } = values;
    const isSuccess = await dispatch(
      tryLogin({
        username,
        password,
      })
    );

    if (!isSuccess) {
      setError("username", {
        type: "custom",
        message: "Sai tài khoản hoặc mật khẩu",
      });
      setError("password", {
        type: "custom",
        message: "Sai tài khoản hoặc mật khẩu",
      });

      return;
    }

    // history.push("/");
  };

  useEffect(() => {
    if (state.loading === false && state.is_logged) {
      if (_user?.permission?.reservation) {
        return history.push("/reservation");
      } else if (_user?.permission?.image) {
        return history.push("/images");
      } else {
        return history.push("/newsfeed");
      }
    }
  }, [
    _user?.permission?.image,
    _user?.permission?.reservation,
    history,
    state.is_logged,
    state.loading,
  ]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "#fff",
    boxShadow: 24,
    color: "#fff",
    padding: "25px 30px",
    overflow: "auto",
    border: "1px solid #00000029",
    width: "400px",
  };

  return (
    <Box sx={style}>
      <form className="c-login__card" onSubmit={handleSubmit(handleLogin)}>
        <Typography variant="h3" sx={{ textAlign: "center" }}>
          Đăng nhập
        </Typography>

        <TextField
          autoFocus
          name="username"
          autoComplete="off"
          {...register("username", {
            onChange: (event) => handleChange(event),
          })}
          placeholder="Tài khoản"
          sx={{
            width: "100%",
            marginTop: "40px",
            marginBottom: "40px",
            "& .MuiInputBase-root": { height: "40px" },
            "& .css-hdw1oc": { float: "left !important" },
          }}
          error={!!errors.username}
          helperText={errors.username && errors.username.message}
        />

        <TextField
          name="password"
          placeholder="Mật khẩu"
          autoComplete="off"
          type="password"
          {...register("password", {
            onChange: (event) => handleChange(event),
          })}
          sx={{
            width: "100%",
            marginBottom: "40px",

            "& .MuiInputBase-root": { height: "40px" },
            "& .css-hdw1oc": { float: "left !important" },
          }}
          error={!!errors.password}
          helperText={errors.password && errors.password.message}
        />

        <Box sx={{ textAlign: "center" }}>
          <Button
            type="submit"
            sx={{
              textAlign: "center",
              background: "#F15E30",
              color: "#fff",

              "&.MuiButtonBase-root:hover": { backgroundColor: "#F15E30" },
            }}
          >
            Đăng nhập
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default LoginPage;
