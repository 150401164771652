import { ACTION } from "@common/constants/redux";

export const setConfigImageFilter = (filter) => {
  return (dispatch) => {
    dispatch({
      type: ACTION.SET_IMAGE_FILTER,
      payload: filter,
    });
  };
};
