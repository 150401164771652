// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

import { getMessaging, getToken, isSupported } from "firebase/messaging";

import { v4 as uuidv4 } from "uuid";
import { pushToken } from "@apis/firebase.api";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBN3fE8u0NbX54cSdz1HyeOGRClMIGQImI",
  authDomain: "vtcode-8e398.firebaseapp.com",
  projectId: "vtcode-8e398",
  storageBucket: "vtcode-8e398.appspot.com",
  messagingSenderId: "754283816551",
  appId: "1:754283816551:web:516682eb7f0b67b6d116c2",
  measurementId: "G-VZFBZ83E4L",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const requestPermission = async () => {
  console.log("Requesting permission...");
  const permission = await Notification.requestPermission();
  console.log("permission", permission);
  if (permission === "granted") {
    console.log("Notification permission granted.");
  }

  return permission;
};

export const checkPermissionNotification = async () => {
  if (!(await isSupported())) return;
  const messaging = getMessaging();
  getToken(messaging, {
    // eslint-disable-next-line no-undef
    vapidKey: process.env.REACT_FIREBASE_VAPIDKEY,
  })
    .then(async (currentToken) => {
      if (currentToken) {
        console.log("currentToken", currentToken);

        const res = await pushToken({
          firebase_token: currentToken,
          device_id: uuidv4(),
          device_type: 0,
          device_info: "iPhone 14",
        });

        console.log("push token firebase", res);
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
    });
};
