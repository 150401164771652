import { Dashboard } from "../modules/dashboard/pages/index";

import {
  BlogListPage,
  CreateBlogPage,
  UpdateBlogPage,
} from "../modules/newsfeed/pages";

import {
  CreateNotiContentPage,
  NotiContentListPage,
  UpdateNotiContentPage,
} from "../modules/notification-content/pages";

import {
  CreateNotiCampaignPage,
  NotiCampaignListPage,
  UpdateNotiCampaignPage,
} from "../modules/notification-campaigns/pages";

import {
  CreateNotiSegmentPage,
  NotiSegmentListPage,
  UpdateNotiSegmentPage,
} from "../modules/notification-segments/pages";

import {
  CreatePromotionPage,
  PromotionListPage,
  UpdatePromotionPage,
} from "../modules/promotion/pages";

import { CustomerPage, UpdateCustomerPage } from "../modules/customer/pages";
import { PointHistoryPage } from "../modules/history-point/pages";
import { TransactionHistoryPage } from "../modules/history-transaction/pages";
import { VoucherHistoryPage } from "../modules/history-voucher/pages";

import {
  RatingCustomerListPage,
  UpdateRatingCustomerPage,
} from "../modules/rating-customer/pages";

import {
  CreateRatingQuestionPage,
  RatingQuestionListPage,
  UpdateRatingQuestionPage,
} from "../modules/rating-question/pages";

import {
  CreateInventoryPage,
  InventoryListPage,
  UpdateInventoryPage,
} from "../modules/inventory/pages";

import { AccountListPage, AccountUpdatePage } from "../modules/account/pages";
import {
  CreateSongPage,
  SongListPage,
  SynchronizedPage,
  UpdateSongPage,
} from "../modules/songs/pages";

import { UpdateTransferPolicyPage } from "../modules/policy/transfer/pages";
import { UpdateCompanyInfoPage } from "../modules/policy/company-infos/pages";

import {
  ContactListPage,
  UpdateContactPage,
} from "../modules/policy/contact/pages";

import {
  CreateQAPage,
  QAListPage,
  UpdateQAPage,
} from "../modules/policy/qa/pages";

import { UpdateReferralPolicyPage } from "../modules/policy/referral/pages";
import { MembershipPage } from "../modules/policy/membership/pages";
import { UpdateMembershipOffersPage } from "../modules/policy/membership/components/membership-offer/pages";

import {
  UpdateNewsfeedTypePage,
  NewsfeedTypesListPage,
  CreateNewsfeedTypePage,
} from "../modules/newsfeeds-types/pages";
import {
  CreateEventpage,
  EventPage,
  UpdateEventpage,
} from "@policy-module/event/pages";

import {
  CreatePopupPage,
  PopupPage,
  UpdatePopupPage,
} from "@popup-module/pages";
import {
  CreateHolidayPage,
  HolidayPage,
  UpdateHolidayPage,
} from "@policy-module/holiday/pages";
import {
  CreateTimeConfigPage,
  TimeConfigPage,
  UpdateTimeConfigPage,
} from "@policy-module/time-config/pages";
import { BookingRoomPage } from "../modules/booking-room/pages";

import {
  ImageListPage,
  CreateImagepage,
  UpdateImagePage,
} from "../modules/images/pages";

const routes = [
  {
    path: "/dashboard",
    component: Dashboard,
  },
  {
    path: "/newsfeed",
    component: BlogListPage,
  },
  {
    path: "/newsfeed/add",
    component: CreateBlogPage,
  },
  {
    path: "/newsfeed/:id",
    component: UpdateBlogPage,
  },
  {
    path: "/bulletin_type",
    component: NewsfeedTypesListPage,
  },
  {
    path: "/bulletin_type/add",
    component: CreateNewsfeedTypePage,
  },
  {
    path: "/bulletin_type/:id",
    component: UpdateNewsfeedTypePage,
  },
  {
    path: "/notification/content",
    component: NotiContentListPage,
  },
  {
    path: "/notification/content/add",
    component: CreateNotiContentPage,
  },
  {
    path: "/notification/content/:id",
    component: UpdateNotiContentPage,
  },
  {
    path: "/notification/campaign",
    component: NotiCampaignListPage,
  },
  {
    path: "/notification/campaign/add",
    component: CreateNotiCampaignPage,
  },
  {
    path: "/notification/campaign/:id",
    component: UpdateNotiCampaignPage,
  },
  {
    path: "/notification/segment",
    component: NotiSegmentListPage,
  },
  {
    path: "/notification/segment/add",
    component: CreateNotiSegmentPage,
  },
  {
    path: "/notification/segment/:id",
    component: UpdateNotiSegmentPage,
  },
  {
    path: "/notification/popup",
    component: PopupPage,
  },
  {
    path: "/notification/popup/add",
    component: CreatePopupPage,
  },
  {
    path: "/notification/popup/:id",
    component: UpdatePopupPage,
  },
  {
    path: "/voucher",
    component: PromotionListPage,
  },
  {
    path: "/voucher/add",
    component: CreatePromotionPage,
  },
  {
    path: "/voucher/:id",
    component: UpdatePromotionPage,
  },
  {
    path: "/customer",
    component: CustomerPage,
  },
  {
    path: "/customer/:id",
    component: UpdateCustomerPage,
  },
  {
    path: "/histories/transaction",
    component: TransactionHistoryPage,
  },
  {
    path: "/histories/promotion",
    component: VoucherHistoryPage,
  },
  {
    path: "/histories/point",
    component: PointHistoryPage,
  },
  {
    path: "/rating/person",
    component: RatingCustomerListPage,
  },
  {
    path: "/rating/person/:id",
    component: UpdateRatingCustomerPage,
  },
  {
    path: "/rating/questions",
    component: RatingQuestionListPage,
  },
  {
    path: "/rating/questions/add",
    component: CreateRatingQuestionPage,
  },
  {
    path: "/rating/questions/:id",
    component: UpdateRatingQuestionPage,
  },

  {
    path: "/account",
    component: AccountListPage,
  },
  {
    path: "/account/:id",
    component: AccountUpdatePage,
  },
  {
    path: "/inventory",
    component: InventoryListPage,
  },
  {
    path: "/inventory/add",
    component: CreateInventoryPage,
  },
  {
    path: "/inventory/:id",
    component: UpdateInventoryPage,
  },
  {
    path: "/song",
    component: SongListPage,
  },
  {
    path: "/song/add",
    component: CreateSongPage,
  },
  {
    path: "/songs/sync",
    component: SynchronizedPage,
  },
  {
    path: "/song/:id",
    component: UpdateSongPage,
  },

  {
    path: "/policies/membership",
    component: MembershipPage,
  },
  {
    path: "/policies/membership/:id",
    component: UpdateMembershipOffersPage,
  },
  {
    path: "/policies/qa",
    component: QAListPage,
  },
  {
    path: "/policies/qa/add",
    component: CreateQAPage,
  },
  {
    path: "/policies/qa/:id",
    component: UpdateQAPage,
  },
  {
    path: "/policies/company_infos",
    component: UpdateCompanyInfoPage,
  },
  {
    path: "/policies/transfer",
    component: UpdateTransferPolicyPage,
  },
  {
    path: "/policies/referral",
    component: UpdateReferralPolicyPage,
  },
  {
    path: "/policies/contact",
    component: ContactListPage,
  },
  {
    path: "/policies/contact/:id",
    component: UpdateContactPage,
  },
  {
    path: "/policies/event",
    component: EventPage,
  },
  {
    path: "/policies/event/add",
    component: CreateEventpage,
  },
  {
    path: "/policies/event/:id",
    component: UpdateEventpage,
  },
  {
    path: "/policies/holiday",
    component: HolidayPage,
  },
  {
    path: "/policies/holiday/add",
    component: CreateHolidayPage,
  },
  {
    path: "/policies/holiday/:id",
    component: UpdateHolidayPage,
  },
  {
    path: "/policies/time-configs",
    component: TimeConfigPage,
  },
  {
    path: "/policies/time-configs/add",
    component: CreateTimeConfigPage,
  },
  {
    path: "/policies/time-configs/:id",
    component: UpdateTimeConfigPage,
  },
  {
    path: "/reservation",
    component: BookingRoomPage,
  },
  {
    path: "/images",
    component: ImageListPage,
  },
  {
    path: "/images/add",
    component: CreateImagepage,
  },
  {
    path: "/images/:id",
    component: UpdateImagePage,
  },
];

const routesReservation = [
  {
    path: "/reservation",
    component: BookingRoomPage,
  },
  // {
  //   path: "/account",
  //   component: AccountListPage,
  // },
];

const routesImage = [
  {
    path: "/images",
    component: ImageListPage,
  },
  {
    path: "/images/add",
    component: CreateImagepage,
  },
  {
    path: "/images/:id",
    component: UpdateImagePage,
  },
];
export { routes, routesReservation, routesImage };
