export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_AUTHENTICATE = "SET_AUTHENTICATE";

export const SET_LOADING = "SET_LOADING";
export const PUSH_NOTIFICATION = "PUSH_NOTIFICATION";
export const REMOVE_NOTIFICATION = "REMOVE_NOTIFICATION";

export const ADD_NEWSFEED = "ADD_NEWSFEED";
export const UPDATE_NEWSFEED = "UPDATE_NEWSFEED";
export const DELETE_NEWSFEED = "DELETE_NEWSFEED";

export const SET_NEWSFEED_FILTER = "SET_NEWSFEED_FILTER";

export const SET_NOTIFICATION_CONTENT_FILTER =
  "SET_NOTIFICATION_CONTENT_FILTER";
export const SET_NOTIFICATION_CAMPAIGN_FILTER =
  "SET_NOTIFICATION_CAMPAIGN_FILTER";
export const SET_NOTIFICATION_SEGMENT_FILTER =
  "SET_NOTIFICATION_SEGMENT_FILTER";

export const SET_CUSTOMER = "SET_CUSTOMER";
export const SET_CUSTOMER_FILTER = "SET_CUSTOMER_FILTER";

export const SET_VOUCHER_FILTER = "SET_VOUCHER_FILTER";
export const SET_INVENTORY_FILTER = "SET_INVENTORY_FILTER";
export const SET_SONG_FILTER = "SET_SONG_FILTER";

export const SET_CONFIG_EVENTS_FILTER = "SET_CONFIG_EVENTS_FILTER";

export const SET_POPUP_FILTER = "SET_POPUP_FILTER";

export const SET_HOLIDAY_FILTER = "SET_HOLIDAY_FILTER";

export const SET_TIME_CONFIG_FILTER = "SET_TIME_CONFIG_FILTER";

export const SET_IMAGE_FILTER = "SET_IMAGE_FILTER";
